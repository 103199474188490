import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import AboutDesktop from "../components/sections/AboutDesktop"
import AboutMobile from "../components/sections/AboutMobile"
import TracksDesktop from "../components/sections/TracksDesktop"
import TracksMobile from "../components/sections/TracksMobile"
import CommunityDesktop from "../components/sections/CommunityDesktop"
import CommunityMobile from "../components/sections/CommunityMobile"
import Speakers from "../components/sections/Speakers"
import Prizes from "../components/sections/Prizes"
import Sponsors from "../components/sections/Sponsors"

const IndexPage = () => (
  <Layout>
    <SEO title="Hack'20 by DubHacks" />
    <section id="about">
      <AboutDesktop />
      <AboutMobile />
    </section>
    <section id="tracks">
      <TracksDesktop />
      <TracksMobile />
    </section>
    <section id="community">
      <CommunityDesktop />
      <CommunityMobile />
    </section>
    <section id="speakers">
      <Speakers />
    </section>
    <section id="prizes">
      <Prizes />
    </section>
    <hr />
    <section id="sponsors">
      <Sponsors />
    </section>
    <hr />
  </Layout>
)

export default IndexPage
