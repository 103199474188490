import psl_logo from "../../images/sponsors/psl.svg"
import logo_1517 from "../../images/sponsors/1517.png"
import google_cloud_logo from "../../images/sponsors/google_cloud.svg"

export const prizes = [
  {
    img: psl_logo,
    name: "HACK'20 Track Winners",
    desc:
      "Winning team from each track will be able to donate $300 to a charity/nonprofit of their choice and receive one on one mentorship from local VC and startup studio, PSL!",
    alt: "Pioneer Square Labs",
  },
  {
    img: logo_1517,
    name: "1517 Project Grant",
    desc:
      "Multiple winners chosen by 1517 will receive a $1K grant, $10K in AWS credits, and more!",
    alt: "1517 Fund",
  },
  {
    img: google_cloud_logo,
    name: "Best Use of Google Cloud",
    desc:
      "The project that best uses Google Cloud will receive a Patagonia backpack, water bottle, trophy, and opportunity to join a hackathon hosted by Google!",
    alt: "Google Cloud",
  },
]
