import psl_logo from "../../images/sponsors/psl.svg"
import psl_wordmark_logo from "../../images/PSL_wordmark.svg"
import logo_1517 from "../../images/sponsors/1517.png"
import google_cloud_logo from "../../images/sponsors/google_cloud.svg"
import voiceflow_logo from "../../images/sponsors/voiceflow.svg"
import f5_logo from "../../images/sponsors/f5.svg"
import echoar_logo from "../../images/sponsors/echoar.png"
import chef_logo from "../../images/sponsors/chef.svg"
import deloitte_logo from "../../images/sponsors/deloitte.svg"
import wolfram_logo from "../../images/sponsors/wolfram.svg"
import accenture_logo from "../../images/sponsors/accenture.svg"
import barn2door_logo from "../../images/sponsors/barn2door.svg"

export const psl = {
  img: psl_logo,
  alt: "Pioneer Square Labs",
}

export const psl_wordmark = {
  img: psl_wordmark_logo,
  alt: "Pioneer Square Labs",
}

export const sponsors = [
  {
    img: logo_1517,
    alt: "1517 Fund",
  },
  {
    img: voiceflow_logo,
    alt: "Voiceflow",
  },
  {
    img: f5_logo,
    alt: "F5 Networks",
  },
  {
    img: chef_logo,
    alt: "Chef",
  },
  {
    img: deloitte_logo,
    alt: "Deloitte",
  },
  {
    img: wolfram_logo,
    alt: "Wolfram",
  },
  {
    img: accenture_logo,
    alt: "Accenture",
  },
  {
    img: barn2door_logo,
    alt: "Barn2Door",
  },
  {
    img: echoar_logo,
    alt: "Echo AR",
  },
  {
    img: google_cloud_logo,
    alt: "Google Cloud",
  },
]
