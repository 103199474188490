import React from "react"

import { psl, psl_wordmark, sponsors } from "./sponsor-data"
import "./Sponsors.scss"

export default () => (
  <div id="sponsors-section">
    <h2>Sponsors</h2>
    <div id="sponsors-container">
      <img className="mobile-only" src={psl.img} alt={psl.alt}></img>
      <img
        className="mobile-none"
        src={psl_wordmark.img}
        alt={psl_wordmark.alt}
      ></img>
      <div id="sponsors-list">
        {sponsors.map(sponsor => (
          <img key={sponsor.alt} src={sponsor.img} alt={sponsor.alt}></img>
        ))}
      </div>
    </div>
  </div>
)
