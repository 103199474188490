import React from "react"
import grid4High from "../../images/mobile_4tall.svg"
import HeaderSVG from "../../images/header.svg"
import PSL_wordmark from "../../images/PSL_wordmark.svg"
import "./AboutMobile.scss"

import { APPLY_LINK } from "../../constants"

export default () => (
  <div id="about-mobile" className="mobile-only">
    <img id="about_grid_top" alt="" src={grid4High} />
    <div id="about-container">
      <h1>
        Hack
        <br />
        <span className="color-blue">'20</span>
      </h1>
      <div className="desc">
        <p className="title-sponsor">
          presented by <img alt="Pioneer Square Labs" src={PSL_wordmark} />
        </p>
        <p className="date">August 15-16, 2020</p>
        <p>
          Hack'20 is a 24-hour online hackathon hosted by DubHacks, empowering
          WA students to create thoughtful, innovative solutions for this year's
          most pivotal challenges.
        </p>
      </div>
      <div id="filler" role="presentation">
        <img alt="" src={grid4High} />
      </div>
      <div id="register">
        <a className="cta-btn" href={APPLY_LINK}>
          Apply Now
        </a>
      </div>
    </div>
    <img id="about_hero" alt="" src={HeaderSVG} />
  </div>
)
