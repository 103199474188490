import React, { useState } from "react"
import classNames from "classnames"

import TracksGridSVG from "../../images/tracks_grid.svg"
import "./TracksDesktop.scss"
import { tracks } from "./tracks-data"
import { accessibleKey } from "../../utils"

export default () => {
  const [selectedTrack, setSelectedTrack] = useState()

  const handleTrackClick = track => {
    return e => {
      e.preventDefault()
      e.stopPropagation()
      setSelectedTrack(track)
    }
  }

  return (
    <div id="tracks-desktop" className="mobile-none">
      <div
        id="tracks-container"
        onClick={() => setSelectedTrack(undefined)}
        onKeyDown={accessibleKey(() => selectedTrack(undefined))}
        tabIndex="0"
        role="button"
      >
        <div id="tracks-list">
          {Object.values(tracks).map(track => (
            <div
              role="button"
              tabIndex="0"
              key={track.id}
              className={classNames([
                "track",
                { active: track.id === selectedTrack },
              ])}
              style={track.styles.desktop}
              onClick={handleTrackClick(track.id)}
              onKeyDown={accessibleKey(handleTrackClick(track.id))}
            >
              <img alt="" src={track.img}></img>
              <h3>{track.name} Track</h3>
            </div>
          ))}
        </div>
        {selectedTrack ? (
          <div id="track-description" className="pane-right">
            <h2>{tracks[selectedTrack].name}</h2>
            <p>{tracks[selectedTrack].desc}</p>
            <ul>
              {tracks[selectedTrack].questions.map(q => (
                <li>{q}</li>
              ))}
            </ul>
          </div>
        ) : (
          <div id="track-general" className="pane-right">
            <h2>Eyes on our new reality</h2>
            <p>
              Our community is in need, so decide where you want to make your
              impact. Follow a track and develop a project best fit to support
              your cause of choice.
            </p>
            <p>
              By choosing a track, you'll find resources and mentors to guide
              your hackathon journey. Can't decide? Customize your own path!
            </p>
            <p>
              <i>Tap a track to learn more</i>
            </p>
          </div>
        )}
      </div>
      <img id="tracks_grid" alt="" src={TracksGridSVG} />
    </div>
  )
}
