import React from "react"

import Desktop12Tall from "../../images/desktop_12tall.svg"
import Mobile4Tall from "../../images/mobile_4tall.svg"
import { speakers } from "./speakers-data"
import "./Speakers.scss"

export default () => (
  <div id="speakers-desktop">
    <h2>Speakers</h2>
    <p>
      Engage with founders, educators, healthcare execs and industry
      trailblazers at our Health Tech panel, Breaking Barriers in Tech panel,
      and more!
    </p>
    <div id="speakers-container">
      {speakers.map(speaker => (
        <div className="speaker-bio" key={speaker.name}>
          <img alt={speaker.name} src={speaker.img}></img>
          <h3>{speaker.name}</h3>
          <p>
            {speaker.desc} <a href={speaker.link}>{speaker.company}</a>
          </p>
          {speaker.note && <p className="speaker-note">{speaker.note}</p>}
        </div>
      ))}
    </div>
    <img src={Desktop12Tall} className="speakers-grid  mobile-none" alt="" />
    <img src={Mobile4Tall} className="speakers-grid mobile-only" alt="" />
    <img
      src={Mobile4Tall}
      className="speakers-grid  speakers-grid-mobile-middle mobile-only"
      alt=""
    />
    <img
      src={Mobile4Tall}
      className="speakers-grid  speakers-grid-mobile-bottom mobile-only"
      alt=""
    />
  </div>
)
