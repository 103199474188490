import React from "react"

import HeaderSVG from "../../images/header.svg"
import AboutGridSVG from "../../images/about_grid_tr.svg"
import PSL_wordmark from "../../images/PSL_wordmark.svg"
import "./AboutDesktop.scss"

import { APPLY_LINK } from "../../constants"

export default () => (
  <div id="about-desktop" className="mobile-none">
    <div id="about-container">
      <h1>
        Hack<span className="color-blue">'20</span>
      </h1>
      <p className="title-sponsor">
        presented by <img alt="Pioneer Square Labs" src={PSL_wordmark} />
      </p>
      <p className="date">August 15-16, 2020</p>
      <p className="desc">
        Hack'20 is a 24-hour online hackathon hosted by DubHacks, empowering WA
        students to create thoughtful, innovative solutions for this year's most
        pivotal challenges.
      </p>
      <a className="cta-btn" href={APPLY_LINK}>
        Register
      </a>
    </div>
    <img id="about_hero" alt="" src={HeaderSVG} />
    <img id="about_grid_tr" alt="" src={AboutGridSVG} />
  </div>
)
