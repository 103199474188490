import CampusTrackSVG from "../../images/track_campus.svg"
import IdentitySVG from "../../images/track_identity.svg"
import QuarantineTrackSVG from "../../images/track_community.svg"

export const tracks = {
  campus: {
    id: "campus",
    name: "Campus",
    img: CampusTrackSVG,
    desc:
      "Technology has allowed us to transition our college experience to virtual platforms, but countless campus industries still endure a multitude of challenges.",
    questions: [
      "Is Zoom the answer to virtual learning?",
      "What kind of support do healthcare workers and researchers need?",
      "How can you help your favorite spot on The Ave?",
    ],
    styles: {
      mobile: {
        left: "10%",
        top: "8%",
        transform: "rotate(13deg)",
      },
      desktop: {
        left: "5%",
        top: "20%",
        transform: "rotate(15deg)",
      },
    },
  },
  identity: {
    id: "identity",
    name: "Identity",
    img: IdentitySVG,
    desc:
      "Growing social movements have compelled us to examine our identities, understand the significance of representation, and question the validity of our allyship.",
    questions: [
      "What role does technology play in advancing social discourse?",
      "How can technology empower underrepresented minorities?",
      "What obstacles stand in the way of modern-day activism?",
    ],
    styles: {
      mobile: {
        right: "0%",
        top: "43%",
        transform: "rotate(-11deg) translateY(-50%)",
      },
      desktop: {
        right: "20%",
        top: "5%",
        transform: "rotate(-15deg)",
      },
    },
  },
  quarantine: {
    id: "quarantine",
    name: "Quarantine",
    img: QuarantineTrackSVG,
    desc:
      "Staying at home has transformed how we interact with others, stay active, and remain productive in school and work.",
    questions: [
      "How can we replace the human connection lost through social distancing?",
      "Is Chloe Ting the replacement for in-person gyms?",
      "Are WFH mandates conducive to productive and healthy environments?",
    ],
    styles: {
      mobile: {
        bottom: "5%",
        left: "40%",
        transform: "translateX(-50%)",
      },
      desktop: {
        left: "30%",
        bottom: "0%",
      },
    },
  },
}
