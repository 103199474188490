import React from "react"

import "./TracksMobile.scss"

import grid4High from "../../images/mobile_4tall.svg"
import rightGrid from "../../images/tracks_mobile_grid_right.svg"
import { tracks } from "./tracks-data"
import Modal from "./Modal"
import { accessibleKey } from "../../utils"

export default () => (
  <div id="tracks-mobile" className="mobile-only">
    <h2>
      Eyes on our
      <br />
      <br />
      <span>new reality</span>
    </h2>
    <div id="tracks-list">
      <img id="top-grid" alt="" src={grid4High} />
      <img id="right-grid" alt="" src={rightGrid} />
      {Object.values(tracks).map(track => (
        <Modal
          key={track.id}
          toggle={onClick => (
            <div
              className={"track"}
              style={track.styles.mobile}
              onClick={onClick}
              onKeyDown={accessibleKey(onClick)}
              role="button"
              tabIndex="0"
            >
              <img src={track.img} alt={track.name} />
              <h3>{track.name}</h3>
            </div>
          )}
          content={{
            image: track.img,
            title: `${track.name} Track`,
            description: track.desc,
            questions: track.questions,
          }}
        />
      ))}
    </div>
    <div id="about-tracks">
      <p>
        Our community is in need, so decide where you want to make your impact.
        Follow a track and develop a project best fit to support your cause of
        choice.
      </p>
      <p>
        By choosing a track, you'll find resources and mentors to guide your
        hackathon journey. Can't decide? Chart your own path and customize your
        own!
      </p>
      <p>
        <i>Tap a track to learn more</i>
      </p>
    </div>
  </div>
)
