import React from "react"

import { prizes } from "./prizes-data"
import AndMore from "../../images/andMore.svg"
import "./Prizes.scss"

export default () => (
  <div id="prizes-desktop">
    <h2>Prizes</h2>
    <div id="prizes-container">
      {prizes.map(prize => (
        <div className="prize" key={prize.name}>
          <div className="logo-container">
            <img alt={prize.alt} src={prize.img}></img>
          </div>
          <h3>{prize.name}</h3>
          <p>{prize.desc}</p>
        </div>
      ))}
      <div className="prize and-more">
        <div className="logo-container">
          <img alt="" className="and-more-img" src={AndMore}></img>
        </div>
        <h3>AND MORE</h3>
      </div>
    </div>
  </div>
)
