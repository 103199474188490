import React, { useState } from "react"

import "./Modal.scss"
import TopGrid from "../../images/about_mobile_grid_top.svg"
import { APPLY_LINK } from "../../constants"

const Modal = props => {
  const [isOpen, setOpen] = useState(false)

  const onClick = () => setOpen(true)

  return (
    <>
      {props.toggle(onClick)}
      {isOpen ? (
        <div className="modalContainer">
          <img className="modalGrid" src={TopGrid} alt="Grid" />
          <button className="modalBackButton" onClick={() => setOpen(false)}>
            Back
          </button>
          <img
            className="modalImage"
            src={props.content.image}
            alt="Track Symbol"
          />
          <div className="modalContentContainer">
            <div className="modalTitle">{props.content.title}</div>
            <div className="scrollArea">
              <div className="modalDescription">
                {props.content.description}
              </div>
              {props.content.questions.map(question => (
                <div className="modalQuestion">{question}</div>
              ))}
            </div>
          </div>
          <a className="modalActionLink" href={APPLY_LINK}>
            Apply Now
          </a>
        </div>
      ) : null}
    </>
  )
}

export default Modal
