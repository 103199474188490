import demmaIMG from "../../images/speakers/demma.png"
import benIMG from "../../images/speakers/ben.png"
import carinaIMG from "../../images/speakers/carina.png"
import joyIMG from "../../images/speakers/joy.png"
import johnIMG from "../../images/speakers/john.png"
import amyIMG from "../../images/speakers/amy.png"
import zafarIMG from "../../images/speakers/zafar.png"
import ellenIMG from "../../images/speakers/ellen.png"

export const speakers = [
  {
    name: "Demma Rosa Rodriguez",
    desc: "Head of Equity Engineering @",
    company: "Google",
    link: "https://google.com",
    note: "Keynote Speaker",
    img: demmaIMG,
  },
  {
    name: "Ben Gilbert",
    desc: "Founder of",
    company: "Pioneer Square Labs",
    link: "https://www.psl.com/",
    img: benIMG,
  },
  {
    name: "Carina Glover",
    desc: "Founder of",
    company: "HerHeadquarters",
    link: "https://herheadquarters.app/",
    img: carinaIMG,
  },
  {
    name: "Joy Ofodu",
    desc: "Associate Brand Marketing Manager @",
    company: "Instagram",
    link: "https://instagram.com",
    img: joyIMG,
  },
  {
    name: "Dr. John Scott",
    desc: "Professor of Medicine & Medical Director of Digital Health @",
    company: "University of Washington",
    link: "https://www.uwmedicine.org",
    img: johnIMG,
  },
  {
    name: "Amy J. Ko, Ph.D.",
    desc: "Professor @",
    company: "University of Washington, Information School",
    link: "https://faculty.washington.edu/ajko/",
    img: amyIMG,
  },
  {
    name: "Dr. Zafar Chaudry",
    desc: "Senior VP & Chief Information Officer @",
    company: "Seattle Children's Hospital",
    link: "https://www.seattlechildrens.org/about/leadership/zafar-chaudry/",
    img: zafarIMG,
  },
  {
    name: "Ellen Wiegand",
    desc: "VP & Chief Information Officer @",
    company: "Virginia Mason Medical Center",
    link: "https://www.virginiamason.org/",
    img: ellenIMG,
  },
]
