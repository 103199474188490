import React from "react"

import CommunitySVG from "../../images/community.svg"
import "./CommunityDesktop.scss"

export default () => (
  <div id="community-desktop" className="mobile-none">
    <img src={CommunitySVG} alt="" />
    <div id="community-container">
      <h2>
        BY LOCAL STUDENTS,
        <br />
        FOR LOCAL COMMUNITIES
      </h2>
      <p>
        Zoom University. Black Lives Matter. A global pandemic. Killer bees.
        2020 has uprooted our lives and left our communities in dire need of
        change. Now, it's your time to hack it.
      </p>
      <p>
        Across our state, healthcare workers are overwhelmed, restaurants are
        closing, educators demand support, and marginalized communities fight
        for their lives. Today, it is our responsibility to join the front lines
        and build solutions fueled by creativity and empathy. Bring your love
        for tech and compassion for your community - we'll help you make it
        happen.
      </p>
    </div>
  </div>
)
